
import React, { useState } from 'react';
import { Accordion, AccordionTitleProps, Button, Icon, Modal, Table, TableCell } from 'semantic-ui-react';
import styled from 'styled-components';

import burialSocietyFlyer from '../../assets/images/burial-society-flyer.png';
import member1 from '../../assets/images/member_1.jpg';
import member11 from '../../assets/images/member_11.jpg';
import member2 from '../../assets/images/member_2.jpg';
import member3 from '../../assets/images/member_3.jpg';
import member6 from '../../assets/images/member_6.jpg';
import member8 from '../../assets/images/member_8.jpg';
import Header from '../../components/Header';
import Layout from '../../components/layout';
import MemberTile from '../../components/MemberTile';

const AboutMembers = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(-1);


  const onClick = (event: React.MouseEvent<HTMLDivElement>, { index }: AccordionTitleProps) => {
    const newIndex: number = activeIndex === index ? -1 : index as number;

    setActiveIndex(newIndex);
  }

  return (
    <Layout title="Member Breakdown" className={className} >
      <Header title="Member Breakdown" />
      <div id="main">
        <section id="content" className="main">
          <header className="major">
            <h2>A breakdown of our member structure</h2>
          </header>
          <Accordion styled fluid>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={onClick}
            >
              <Icon name='dropdown' />
              What is the difference between Executive and Non-Executive Directors?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                All the companies are managed and run by the company's top executives, i.e. the board of directors
                who are appointed by the shareholders at the general meeting to handle the affairs of the company
                on their behalf.
                The composition of the company's Board of Directors includes both Executive and Non-Executive
                Directors, wherein the Executive Directors have management responsibilities.
                Hence, the executive directors look after the routine operations. They represent the senior
                management staff of the organization.

                On the contrary, the Non-Executive Director, shortly referred to as NED does not have management
                responsibilities. They are the guardian of neutralness, who seek to safeguard the company's interest.

                While the executive director possesses full-fledged knowledge of the company, the non-executive
                director is supposed to have a broader oversight.
              </p>
              <b>Comparisson Chart:</b><br />
              <Table celled size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Basis for Comparisson</Table.HeaderCell>
                    <Table.HeaderCell>Executive Director</Table.HeaderCell>
                    <Table.HeaderCell>Non-Executive Director</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Principal Insured / Spouse</Table.Cell>
                    <Table.Cell>R 10 000</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Meaning </Table.Cell>
                    <Table.Cell>An Executive Director is the one involved in the routine management of the firm as well as he/she is the full-time employee of the company.</Table.Cell>
                    <Table.Cell>A Non-Executive Director is a member of the company's board, but he/she does not possess the management responsibilities.</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Represents</Table.Cell>
                    <Table.Cell>Internal directors</Table.Cell>
                    <Table.Cell>External directors</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Appointment by</Table.Cell>
                    <Table.Cell>Letter of appointment</Table.Cell>
                    <Table.Cell>Letter of appointment</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Appointment to board</Table.Cell>
                    <Table.Cell>By nomination committee or by shareholders (as the case may be)</Table.Cell>
                    <Table.Cell>By shareholders</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Independence</Table.Cell>
                    <Table.Cell>Not independence</Table.Cell>
                    <Table.Cell>Independence</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Remuneration</Table.Cell>
                    <Table.Cell>Salary</Table.Cell>
                    <Table.Cell>Service fee</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Strategy</Table.Cell>
                    <Table.Cell>Formulation and implementation</Table.Cell>
                    <Table.Cell>Consideration and review</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Includes</Table.Cell>
                    <Table.Cell>CEO, MD, CFO, etc.</Table.Cell>
                    <Table.Cell>Chairman</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={onClick}
            >
              <Icon name='dropdown' />
              What is in an executive committee?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                An executive committee is composed of elected board leaders and acts as a steering committee for
                the full board. Its main purpose is to facilitate decision-making between board meetings or in urgent
                and crisis circumstances. It has the power to act on behalf of the full board and is a steering
                committee for the board.
              </p>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={onClick}
            >
              <Icon name='dropdown' />
              What is a non-executive committee?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Three common ways for a prospec
                Non-executive directors provide independent oversight and serve on committees concerned with
                sensitive issues such as the pay of the executive directors and other senior managers; they are
                usually paid a fee for their services but are not regarded as employees.
              </p>
            </Accordion.Content>
          </Accordion>
        </section>
      </div>
    </Layout >
  )
}

export default styled(AboutMembers)`
  & header {
    text-align: center;
    &.major h2:after {
      margin: auto;
    }
    @media screen and (max-width: 736px) {
      &.major {
        margin-bottom: 2em;
      }
    }
  }
`;
